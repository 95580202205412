import accordion  from './components/Accordion';
import MicroModal from './components/MicroModal';

function modal() {
	const settings       = {
		openTrigger         : 'data-modal-open',
		closeTrigger        : 'data-modal-close',
		openClass           : 'is-open',
		awaitOpenAnimation  : true,
		awaitCloseAnimation : true,
	};
	window.startModal    = function (id) {
		MicroModal.show(
			id,
			settings,
		);
	};
	const modalCloseLink = document.querySelector('.js-modal-close');
	if (modalCloseLink) {
		modalCloseLink.addEventListener('click', (e) => {
			e.preventDefault();
			const { id } = e.target.dataset;
			if (id) {
				MicroModal.close(id, settings);
			}
		});
	}

	MicroModal.init(settings);
}

// Init
function init() {
	accordion();
	modal();
}

(function () {
	init();
}());
