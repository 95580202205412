const accordion = () => {
	const accordions    = document.querySelectorAll('.accordion');
	let activeAccordion = null;

	accordions.forEach((el) => {
		el.addEventListener('click', (e) => {
			const self    = e.currentTarget;
			const control = self.querySelector('.accordion__control');
			const content = self.querySelector('.accordion__content');

			if (e.target.closest('.accordion__content')) {
				return;
			}

			if (self === activeAccordion) {
				self.classList.remove('open');
				control.setAttribute('aria-expanded', false);
				content.setAttribute('aria-hidden', true);
				activeAccordion = null;
			} else {
				if (activeAccordion) {
					const activeControl = activeAccordion.querySelector('.accordion__control');
					const activeContent = activeAccordion.querySelector('.accordion__content');
					activeAccordion.classList.remove('open');
					activeControl.setAttribute('aria-expanded', false);
					activeContent.setAttribute('aria-hidden', true);
				}

				self.classList.add('open');
				control.setAttribute('aria-expanded', true);
				content.setAttribute('aria-hidden', false);

				activeAccordion = self;
			}
		});
	});
};

export default accordion;
